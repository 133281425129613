/* CONTAINERS */
#social-bar {
    margin-left: 10px;
    padding: 5px 5px;
    height: 35vh;
    width: 10vw;
    background-color: transparent;
}
#social-bar div{
    display: flex;
    justify-content: center;
    align-items: center;
}


/* DASHED-LINE */
#social-bar div div{
    border-left: 4px dashed var(--primary-color);
    height: 15vh;
}

/* IMAGE */
#social-bar div img{
    width: 40px;
    height: 40px;
 }
#social-bar div img:hover{
    position: relative;
    left: -2px;
    background-image: radial-gradient(white,transparent 75%);
}


/* RESPONSIVE DESIGN */
@media (max-width: 767.98px) {
    #social-bar div img{
        width: 32px;
        height: 32px;
     }
     #social-bar {
        margin-left: 6px;
     }
}
