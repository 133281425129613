.cardGroup{
    display: flex;
    flex-wrap: wrap; /* Added 'flex-wrap: wrap' to allow wrapping of cards to new rows */
    justify-content: flex-start;
    padding-left: 50px;
    /*min-height: 50svh;*/
    min-height: calc(50svh - env(safe-area-inset-top) - env(safe-area-inset-bottom)); 
}
.card {
    aspect-ratio: 1 / 0.5;
    background-color: rgba(0, 0, 0, 0);
    width: 27%;
    height: 350px;  /* Changed from percent to fix so we can make cardGroup min-height and adjust automatically without cards changing too */ 
}
.card button{
    padding: 10px;
    width: 100%;
    height: 100%;
}
.card h3 {
    color: var(--secondary-color);
}

.imgContainer{
    display: flex;
    justify-content: center;
    width: 100%;
}
.imgContainer img, .imgContainer2 img {
    width: 6em;
    height: 6em;
}


/* RESPONSIVE MOBILE DESIGN */
@media (max-width: 991.98px) {
    .cardGroup{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        min-height: 800px;
        padding: 0; 
    }
    .card {
        margin: 0;
        aspect-ratio: 1 / 1;

        width: 80%;
        height: auto;  
    }
    .imgContainer img {
        width: auto;
        
    }
    .imgContainer2 img {
        width: 80%;

    }
    .card h3 {
        font-size: large;
    }
    .card h5 {
        font-size: small;
    }
}
/* RESPONSIVE DESKTOP+ DESIGN */
@media only screen and (max-width: 1499.98px) and (min-width: 992px) {
    .cardGroup{
        min-height: 33svh; /* adjust if more rows */
    }
}
@media only screen and (max-width: 1999.98px) and (min-width: 1500px) {
    .cardGroup{
        min-height: 50svh; /* adjust if more rows */
    }
}
