/* Get all child text elements */
#heroTextContainer > *{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 70%;
}


/* Re-Coloring Text Elements */
#heroTextContainer p:nth-child(1){
    color: var(--primary-color);
}
#heroTextContainer h5{
    color: var(--primary-color);
}
#heroTextContainer h1{
    border-bottom: 2px ridge var(--primary-color);
}
#heroTextContainer h1 span{
    color: var(--primary-color);
    font-size: 4rem;
    padding: 0 5px;
    font-family: inherit;
}


/* ********** */
/*   IMAGES   */
/* ********** */
#heroImageContainer img {
    background-color: var(--primary-color-transparent);
    animation: bg-pulser 5s infinite;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px inset var(--primary-color);
    padding: 15px;
    transition: padding 0.1s ease-in;
}



/* RESPONSIVE MOBILE DESIGN */
@media (min-width: 992px) {
    #heroImageContainer img:hover{
        padding: 5px;
    }
}
@media (max-width: 991.98px) {
    #heroTextContainer h1 span{
        font-size: 2rem;
    }
    #heroTextContainer > *{
        justify-content: center;
        text-align: center;
        width: 100%;
    }
    #heroImageContainer{
        display: flex;
        justify-content: center;
    }
    #heroImageContainer img {
        width: 75%;
    }
}