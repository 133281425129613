/* **************** */
/*    CONTAINERS    */
/* **************** */
.contact {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}
.contactContent{
    display: flex;
    align-items: center;
}

.formElement{
    width: 100%;
}

.form{
    display: flex;
    justify-content: center;
}
.inputSpan{
    background-color: var(--primary-color);
}
.inputSpan i{
    color: white;
}

/* RESPONSIVE MOBILE DESIGN */
@media (max-width: 991.98px) {
    .contactContent {
        padding: 0px 8px;
        text-align: center;
    }
    .formElement{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

