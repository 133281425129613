.navbar{
    border-bottom: 1px solid var(--primary-color-transparent);
    background-color: var(--black-transparent-75);
    width: 100%;
    height: 10vh;
    z-index: 10;
    position: fixed;
}

/* Logo Image */
#navLogoContainer{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#navLogoContainer img{
    aspect-ratio: 1/1;
    width: 90%;
    
    animation: load-navbar-logo var(--section_animation-duration) 1s 1 forwards;
    animation-play-state: paused;
    position: relative;
    opacity: 0%;
    right: 50%;
}

/* Nav Links (list items) */
#navLinkList li{
    animation: load-navbar var(--section_animation-duration) 1 forwards;
    animation-play-state: paused;
    font-family: var(--main-typo); 
    position: relative;
    top: -80px;
    cursor: pointer; 
}
/* Link Text re-coloring */
#navLinkList li span:nth-child(1){
    color: var(--primary-color);
}


// ANIMATION DELAY
#navLinkList li:nth-child(1) {
    animation-delay: 0s;
}
#navLinkList li:nth-child(2) {
    animation-delay: 0.25s;
}
#navLinkList li:nth-child(3) {
    animation-delay: 0.5s;
}
#navLinkList li:nth-child(4) {
    animation-delay: 0.75s;
}
#navLinkList li:nth-child(5) {
    animation-delay: 1.0s;
}
#navLinkList li:nth-child(6) {
    animation-delay: 1.25s;
}



/* RESPONSIVE DESIGN */ 
@media (min-width: 992px) {
    #navLinkList li:hover span:nth-child(2){
        position: relative;
        bottom: -1.5px;
        border-bottom: 2px solid var(--primary-color);
    }
}
@media (max-width: 991.98px) {
    #navLinkList {
        background-color: black;
        border: 2px solid var(--primary-color-transparent);
        padding: 15px;
        width: 100%;
    }
    #resumeForm{
        display: flex;
        justify-content: center;
    }
    #resumeForm button{
        margin-top: 15px;
        width: 60%;
    }
    #navLinkList li{
        position: initial;
        animation: none;
    }
    #navLogoContainer img{
        position: initial;
        animation: none;
        opacity: 100%;
    }
}


/* ANIMATIONS */
@keyframes load-navbar-logo {
    from{
        right: 50%;
        opacity: 0%;
    }
    to{
        right: 0%;
        opacity: 100%;
    }
}
@keyframes load-navbar {
    from{
        top: -80px;
    }
    to{
        top: 0px;
    }
}
