/* **************** */
/*    CONTAINERS    */
/* **************** */
#about .column{
    justify-content: center;
}

/* *********** */
/*    IMAGE    */
/* *********** */
#aboutImageContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
#aboutImageContainer img{
    width: 100%;
    height: auto;
    background-color: var(--primary-color-transparent);
    animation: bg-pulser 5s infinite;
    border: 5px inset var(--primary-color);
    padding: 15px;
    border-radius: 25%;
    transition: padding 0.1s ease-in;
}
#aboutImageContainer img:hover{
    padding: 5px;
}



/* RESPONSIVE MOBILE DESIGN */
@media (max-width: 991.98px) {
    #about .column{
        justify-content: center;
        padding-right: 5%;
        padding-left: 5%;
    }
    #aboutTextContainer{
        padding: 0px 10px;
        text-align: justify;
        text-justify: inter-word;
    }
}

