

:root{

  --background-color: #2b2b2b;
  --primary-color: #5adddd;
  --secondary-color: #f5f5f5;


  --text-color: #a8a8a8;
  --text-color-faded: #505050;


  --black-transparent-25: rgba(0, 0, 0, 0.25);
  --black-transparent-75: rgba(0, 0, 0, 0.75);
  --primary-color-transparent: rgba(90, 221, 221, 0.171);


  --main-typo: 'PT Mono';
  --secondary-typo: 'Lucida Console';


  --section_animation-duration: 0.80s;
  
}

html{
    -webkit-text-size-adjust: 100%;
}
body{
    background-color: var(--background-color);
    width: 100%;
    margin: 0px;
    padding: 0px;
}

main{
    width: 100%;
}
/**************/
/* CONTAINERS */
/**************/
section{
    display: flex;
    margin: 0px;
    padding: 5% 15%;
    width: 100%;
    min-height: 100svh;
    /*min-height: calc(100svh - env(safe-area-inset-top) - env(safe-area-inset-bottom));*/ 
    
}
.column{
    display: flex;
    align-items: center;
}



/**********/
/* BUTTONS */
/**********/
button, .custom-btn{
    font-family: var(--secondary-typo);
    color: var(--primary-color);
    background-color: var(--black-transparent-25);
    border: 2px outset var(--primary-color);
    border-radius: 10%;
}
button:hover, .custom-btn:hover{
    background-color: rgba(91, 192, 222, 0.25);
    color: var(--primary-color);
    animation: bg-pulser 2s infinite;
    position: relative;
    bottom: -1.5px;
}
.custom-btn{
    padding: 2% 7%;
    font-size: large;
}
@keyframes bg-pulser {
    0%{
        background-color: rgba(91, 192, 222, 0.020);
    }
    50%{
        background-color: rgba(91, 192, 222, 0.25);
    }
    100%{
        background-color: rgba(91, 192, 222, 0.020);
    }
}


/**********/
/*  TEXT  */
/**********/
.jumbo-text {
    font-size: 2.5rem;
    font-family: var(--secondary-typo);
    color: var(--primary-color);
}
p, li, span {
    font-family: var(--secondary-typo);
    color: var(--text-color);
}
p {
    display: flex;
}
h1 {
    color: var(--secondary-color);
    font-size: 4rem;
}
h2 {
    color: var(--secondary-color);
    font-size: 2.5rem;
}
h3 {
    color: var(--text-color);
}
s{
    color: var(--text-color-faded);
}
i{
    color: var(--primary-color);
}




/*********************/
/*   SECTION HEADER  */
/*********************/
.section-header { 
    padding-top: 25px; 
    padding-bottom: 25px;  
    margin: 0;
    display: flex;
    align-items: center;
}
/* Index */
.section-header span:nth-child(1){
    color: var(--primary-color);
    font-size: large;
}
/* Text */
.section-header span:nth-child(2){
    width: 30%;
    font-size: 1.7rem;
    color: var(--text-color);
}
/* Line */
.section-header span:nth-child(3) {
    width: 100%;
    border-top: 1px solid var(--text-color-faded);
}


/**************/
/*  CARDS  */
/**************/
.cardDisabled{
    cursor: none;
    filter: brightness(0.5) saturate(0);
}

/**************/
/*  SCROLL-BAR  */
/**************/
/* width */
::-webkit-scrollbar {
    width: 10px;
} 
/* Track */
::-webkit-scrollbar-track {
    background: var(--black-transparent-25);
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
}


/***********/
/*  IMAGE  */
/***********/
.png-colorizer{
    /* Changes color of png to the primary color */
    filter: invert(80%) sepia(79%) hue-rotate(124deg) saturate(326%) brightness(87%) contrast(99%);
}

/****************/
/*  MOBILE RESPONSIVE  */
/****************/
@media (max-width: 991.98px) {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 2rem;
    }
    .jumbo-text {
        font-size: 1.8rem;
    }
    .section-header span:nth-child(2){
        /* Adjust header size to push header line back */
        width: 100%;
    }
    section{
        padding: 5% 5%;
    }
}