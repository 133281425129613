/* LOGO AND CONTAINER */
#splash-element{
    background-color: rgb(19, 19, 19);
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#splash-logo{
    opacity: 0%;
    z-index: 2;
    font-size: 200%;
    font-weight: 600;
    position: relative;
    animation: 0.65s linear logo-anime2;
    animation-iteration-count: 1; 
}


/* BACKGROUND GLOW */
#splash-logo div{
    background-color: rgb(255, 252, 252);
    position: absolute;
    z-index: -2;
    opacity: 50%;
    width: 50%;
    height: 100%;
    top: 0%;
    animation: glow-animation 0.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
#splash-logo div:nth-child(1){
    filter: blur(60px);
    left: -10%;
    animation-direction: alternate;
}
#splash-logo div:nth-child(2){
    left: 50%;
    filter: blur(50px); 
    border: 0px;
    border-radius: 50%;
    animation-direction: alternate-reverse;
}

/* ANIMATION */
@keyframes glow-animation {
    0%{
        opacity: 100%;
    }
    100%{
        opacity: 1%;
    }
}
@keyframes logo-anime2 {
    0% {
        background-image: -webkit-linear-gradient(left,red, green, blue);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        opacity: 0%;
    }
    10%{
        opacity: 100%;
    }
    50% {
        background-image: -webkit-linear-gradient(left, blue, red, green);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
    }
    90%{
        opacity: 100%;
    }
    100% {
        background-image: -webkit-linear-gradient(left ,green, blue, red);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        opacity: 0;
    }
}

/* RESPONSIVE MOBILE DESIGN */
@media (max-width: 991.98px) {
    #splash-logo{
        font-size: 150%;
    }
}